import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";

const ButtonWrapperBtn = styled.button`
  font-size: 16pt;
  border: none;
  align-self: center;
  color: ${colors.white};
  background-color: #edbd7f;
  min-width: 120px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  opacity: 0.95;
  user-select: none;
  text-decoration: none;
  &:hover {
    opacity: 1;
  }
`;

const ButtonWrapper = styled.button`
  font-size: 16pt;
  border: none;
  align-self: center;
  color: ${colors.white};
  background-color: #edbd7f;
  min-width: 120px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  opacity: 0.95;
  user-select: none;
  text-decoration: none;
  &:hover {
    opacity: 1;
  }
`;

const Button = ({
  children,
  btn,
  ...props
}: {
  children: any;
  onClick?: (e?: any) => void;
  style?: any;
  btn?: boolean;
  [k: string]: any;
}) => {
  return (
    <>
      {btn ? (
        <ButtonWrapperBtn {...props}>{children}</ButtonWrapperBtn>
      ) : (
        <ButtonWrapper {...props}>{children}</ButtonWrapper>
      )}
    </>
  );
};

export default Button;
