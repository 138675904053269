import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DebrisTrackerContext } from "../../../context/DebrisTrackerContext";
import background from "../../../images/beach-clean-hero.jpg";
import { loginUser, pullUser } from "../../../mdtApi/loginUser";
import Button from "../../Button/Button";

const LoginWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  justify-content: center;
`;

const LoginInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 40px 0px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    width: 75%;
  }
`;

const LoginHeader = styled.div``;

const LoginBackground = styled.div`
  display: flex;
  width: 50%;
  background-color: black;
  background-image: url(${background});
  background-size: cover;
  background-position: 100% 0;
  background-repeat: no-repeat;
  box-shadow: inset 0 300px 300px -100px rgba(0, 0, 0, 0.4);

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const LoginForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 40px;

  @media only screen and (max-width: 767px) {
    margin-top: 60px;
    margin-bottom: 0px;
  }
`;

const LoginInputs = styled.form`
  display: flex;
  flex-direction: column;
  width: 350px;
`;

const LoginSignup = styled.div``;

const LoginInput = styled.input`
  border: 1px solid #d4d4d4;
  font-family: "Lato";
  font-size: 12pt;
  padding: 20px;
  margin: 10px 0px;
`;

const ErrorContainer = styled.div`
  padding: 20px;
  border: solid 2px #801801;
  color: #801801;
  background-color: #dfdfdf;
`;

const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [err, setErr] = useState<string>("");

  const createErr = (err) => {
    setErr(err);
    setTimeout(() => {
      setErr("");
    }, 2000);
  };

  const { setUser } = useContext(DebrisTrackerContext);

  const formRef = useRef<any>(undefined);

  useEffect(() => {
    // Handle query params
    (async () => {
      const parsedQuery = new URLSearchParams(window.location.search);
      // logout the user
      if (parsedQuery.has("logout")) {
        try {
          if (parsedQuery.get("logout") === "true") {
            sessionStorage.removeItem("user");
            setUser(undefined);
            navigate("/login");
          }
        } catch (error) {
          console.error(error);
        }
      }

      // Login with temp token (scistarter)
      if (parsedQuery.has("tempToken")) {
        try {
          setLoading(true);
          const token = parsedQuery.get("tempToken");
          const data = await pullUser(token);
          storeUser(data);
        } catch (e) {
          createErr("Failed to login.");
          console.error(e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  const handleLogin = async (e: any) => {
    if (e) {
      e.preventDefault();
    }
    login(email, password);
  };

  const login = async (userEmail: string, userPassword: string) => {
    setLoading(true);
    try {
      const data = await loginUser(userEmail, userPassword);
      if (!data || data.error) {
        createErr("Your email or password is incorrect.");
      } else {
        storeUser(data);
      }
    } catch (error) {
      createErr("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const storeUser = async (data) => {
    setUser(data);
    sessionStorage.setItem("user", JSON.stringify(data));
    navigate("/");
  };

  return (
    <LoginWrapper>
      <LoginInputContainer>
        <LoginHeader>
          <a href="/">
            <StaticImage
              src="../../../images/MarineDebrisTracker_Logo_Horizontal2.svg"
              quality={100}
              width={350}
              alt="Marine Debris Tracker"
              placeholder="tracedSVG"
            />
          </a>
        </LoginHeader>
        <LoginForm>
          <LoginInputs ref={formRef} onSubmit={handleLogin}>
            {err && <ErrorContainer>{err}</ErrorContainer>}
            <input type="submit" style={{ display: "none" }} />
            <LoginInput
              value={email}
              autoComplete="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder={"EMAIL"}
            />
            <LoginInput
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder={"PASSWORD"}
              type="password"
              autoComplete="current-password"
            />
            <Button
              btn={true}
              onClick={(e) => {
                e.preventDefault();
                handleLogin(undefined);
              }}
              style={{ width: "100%", padding: "20px 0px" }}
            >
              {loading ? "Authorizing..." : "Log in"}
            </Button>
            <a
              style={{
                marginTop: "10px",
                textDecoration: "none",
                fontStyle: "italic",
              }}
              href="/forgot-password"
            >
              Forgot your password?
            </a>
            {/* <LoginScistarter /> */}
          </LoginInputs>
        </LoginForm>
        <LoginSignup>
          Don't have an account?{" "}
          <a href="/sign-up" style={{ textDecoration: "none" }}>
            Sign up!
          </a>
        </LoginSignup>
      </LoginInputContainer>
      <LoginBackground />
    </LoginWrapper>
  );
};

export default Login;
