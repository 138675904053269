export const colors = {
  orange: "#edbd7f",
  blue: "#62789d",
  lightBlue: "#5f6871",
  bottleBlue: "rgba(127, 174, 195, 1)",
  white: "#ffffff",
  black: "#000000",
  grey: "#808080",
  green: "#ACED80",
  red: "#ED7E89",
};
