import fetch from "isomorphic-fetch";
import { devAPI } from "../util/urls";

export const loginUser = async (email: string, password: string) => {
  const res = await fetch(`${devAPI}/users/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  });
  if (res.status !== 200) throw new Error("Failed to login user");
  let parsed = await res.json();
  return parsed;
};

export const pullUser = async (tempToken: string) => {
  try {
    const res = await fetch(`${devAPI}/users/login/code`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ tempToken }),
    });
    let parsed = await res.json();
    return parsed;
  } catch (err) {
    return err;
  }
};

export const logoutUser = async (accessToken: string) => {
  try {
    const res = await fetch(`${devAPI}/users/logout`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ accessToken }),
    });
    const parsed = await res.json();
    if (!parsed.message) throw "Failed to logout";

    return true;
  } catch (err) {
    return false;
  }
};
